<template>
    <dashboard-layout>
        <!-- content body -->
        <div class="content-area__body">
            <!-- settings section -->
            <section class="settings-section section-gap-30">
                <SettingSidebar></SettingSidebar>

                <div class="setting-content" id="company_setup">
                    <div class="section-title-wrap mb-0 d-flex justify-content-between align-items-start">
                        <div class="section-title margin-deliver">
                            {{ $t("Company Setup") }}
                            <v-popover offset="10" :disabled="false" style="display:initial;">
                                <button class="ej__tooltip__button ml-1"><i class="eicon e-question"></i></button>
                                <template slot="popover">
                                    <a class="tooltip-close" v-close-popover><i class="eicon e-close"></i></a>
                                    <div style="text-align:left;">
                                        <a v-close-popover href="https://easy.jobs/docs/update-company-profile"
                                           target="_blank">
                                            {{ $t(`How to update company settings?`) }}
                                        </a>
                                    </div>

                                </template>
                            </v-popover>
                        </div>
                    </div>
                    <form action @submit.prevent="">
                        <div class="form-box">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>{{ $t(`Company Name`) }}</label>
                                        <input
                                                type="text"
                                                class="form-control"
                                                maxlength="50"
                                                :placeholder="$t(`Your Company Name`)"
                                                v-model="form.company.name"
                                                required
                                        />
                                        <error-message :message="errors['company.name']"/>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>{{ $t(`User Name / Company`) }}</label>
                                        <input
                                                type="text"
                                                maxlength="50"
                                                disabled
                                                class="form-control"
                                                :placeholder="$t(`Your User Name / Company`)+ `*`"
                                                v-model="form.company.username"
                                                required
                                        />
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>{{ $t(`Mobile number`) }}</label>
                                        <input
                                                type="text"
                                                class="form-control"
                                                maxlength="20"
                                                :placeholder="$t(`0123456789`)"
                                                v-model="form.company.mobile_number"
                                                required
                                        />
                                        <error-message :message="errors['company.mobile_number']"/>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>{{ $t(`Industry`) }}</label>
                                        <multiselect
                                                id="company_types"
                                                v-model="form.company.company_type"
                                                :options="companyTypes"
                                                :placeholder="$t(`Select Industry`)"
                                                track-by="id"
                                                label="name"
                                        ></multiselect>
                                        <error-message :message="errors['company.company_type']"/>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>{{ $t(`Country`) }}</label>
                                        <multiselect
                                                id="country"
                                                v-model="form.companyAddress.country"
                                                :options="countries"
                                                :placeholder="$t(`Select Country`)"
                                                @open="getCountry"
                                                @select="resetCountryAddress"
                                                track-by="id"
                                                label="name"
                                        ></multiselect>
                                        <error-message :message="errors['companyAddress.country']"/>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>{{ $t(`State`) }}</label>
                                        <multiselect
                                                id="state"
                                                v-model="form.companyAddress.state"
                                                :options="states"
                                                :placeholder="$t(`Select State`)"
                                                @open="getState"
                                                @select="resetStateAddress"
                                                track-by="id"
                                                label="name"
                                        ></multiselect>
                                        <error-message :message="errors['companyAddress.state']"/>
                                    </div>
                                </div>

                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label>{{ $t(`City`) }}</label>
                                        <multiselect
                                                id="city"
                                                v-model="form.companyAddress.city"
                                                :options="cities"
                                                :placeholder="$t(`Select City`)"
                                                @open="getCity"
                                                track-by="id"
                                                label="name"
                                        ></multiselect>
                                        <error-message :message="errors['companyAddress.city']"/>
                                    </div>
                                </div>

                                <div class="col-md-3" id="website-block">
                                    <div class="form-group">
                                        <label>{{ $t(`Postal Code`) }}</label>
                                        <input type="text" maxlength="20" v-model="form.companyAddress.postal_code"
                                               :placeholder="$t(`1234`)" class="form-control"/>

                                        <error-message :message="errors['companyAddress.postal_code']"/>
                                    </div>
                                </div>

                                <div class="col-md-6 col-sm-12">
                                    <div class="form-group">

                                        <label>{{ $t(`Time zone`) }}</label>

                                        <multiselect
                                                id="salary_type"
                                                v-model="timezone"
                                                :options="timeZones"
                                                :placeholder="$t('Select timezone')"
                                                track-by="time_zone"
                                                label="time_zone"
                                                name="time-zone"
                                                :allow-empty="false"
                                                deselect-label=""
                                        ></multiselect>
                                        <error-message :message="errors['company.timezone']"/>
                                    </div>
                                </div>

                                <div class="col-md-6 col-sm-12">
                                    <div class="form-group">
                                        <label for="lang_choose">{{ $t(`Language`) }}</label>
                                        <multiselect
                                                id="lang_choose"
                                                v-model="form.lang"
                                                :options="languages"
                                                :placeholder="$t(`Select Language`)"
                                                track-by="code"
                                                label="title"
                                        ></multiselect>
                                        <error-message :message="errors['lang']"/>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="website">{{ $t(`Website URL`) }}</label>
                                        <label class="checkbox float-right">
                                            <input type="checkbox" v-model="form.company.show_explore_company"/>
                                            <span>{{ $t(`show explore button`) }}</span>
                                            <v-popover offset="10" :disabled="false" style="display: initial">
                                                <button class="ej__tooltip__button ml-1"><i
                                                        class="eicon e-question"></i></button>
                                                <template slot="popover">
                                                    <a class="tooltip-close" v-close-popover><i
                                                            class="eicon e-close"></i></a>
                                                    <a v-close-popover
                                                       href="https://easy.jobs/docs/how-to-disable-explore-company-website-button/"
                                                       target="_blank"
                                                       v-html="$t(`Disable ‘Explore Company Website’ Button`)">
                                                    </a>
                                                </template>
                                            </v-popover>
                                        </label>

                                        <input type="text" maxlength="100" id="website" v-model="form.company.website"
                                               class="form-control" ref="website_url"
                                               :placeholder="$t(`Enter Website URL`)"/>

                                        <error-message :message="errors['company.website']"/>
                                    </div>
                                </div>
                            </div>
                            <div class="row employeeNumber">
                                <label>{{ $t(`Number of Employees`) }}</label>
                                <div class="col-md-3">
                                    <div class="employeeNumber__counter">
                                        <input type="radio" id="employee-count1" value="1"
                                               v-model="form.company.company_size"/>
                                        <label class="employeeNumber__info" for="employee-count1">
                                            <h4 class="employeeNumber__total">1-30</h4>
                                            <span class="employeeNumber__title">{{ $t(`Employees`) }}</span>
                                        </label>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="employeeNumber__counter">
                                        <input type="radio" v-model="form.company.company_size" value="2"
                                               id="employee-count2"/>
                                        <label class="employeeNumber__info" for="employee-count2">
                                            <h4 class="employeeNumber__total">31-60</h4>
                                            <span class="employeeNumber__title">{{ $t(`Employees`) }}</span>
                                        </label>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="employeeNumber__counter">
                                        <input type="radio" v-model="form.company.company_size" value="3"
                                               id="employee-count3"/>
                                        <label class="employeeNumber__info" for="employee-count3">
                                            <h4 class="employeeNumber__total">61-90</h4>
                                            <span class="employeeNumber__title">{{ $t(`Employees`) }}</span>
                                        </label>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="employeeNumber__counter">
                                        <input type="radio" id="employee-count4" v-model="form.company.company_size"
                                               value="4"/>
                                        <label class="employeeNumber__info" for="employee-count4">
                                            <h4 class="employeeNumber__total">100+</h4>
                                            <span class="employeeNumber__title">{{ $t(`Employees`) }}</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group mt-2">
                                        <label>{{ $t(`Write Description`) }}</label>
                                        <div class="editor__wrapper ej-ck-editor" id="company-description">
                                            <ckeditor :editor="editor" :config="CDEditorConfig"
                                                      v-model="form.company.description"
                                                      @ready="onCDReady"></ckeditor>
                                            <error-message :message="errors['company.description']"/>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12" id="benefits">
                                    <div class="form-group mt-2">
                                        <label for="benefits">{{ $t("Company Benefits") }}</label>
                                        <div class="editor__wrapper ej-ck-editor" id="company-benefits">
                                            <ckeditor :editor="editor" :config="CBEditorConfig"
                                                      v-model="form.company.benefits"
                                                      @ready="onCBReady"></ckeditor>
                                            <error-message :message="errors['company.benefits']"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label class="checkbox">
                                            <input type="checkbox" v-model="form.company.show_job_filter"/>
                                            <span>{{ $t(`Show job filter on company page`) }}</span>
                                            <v-popover offset="10" :disabled="false" style="display: initial">
                                                <button class="ej__tooltip__button ml-1"><i
                                                        class="eicon e-question"></i></button>
                                                <template slot="popover">
                                                    <a class="tooltip-close" v-close-popover><i
                                                            class="eicon e-close"></i></a>
                                                    <a v-close-popover
                                                       href="https://easy.jobs/docs/job-filter-company-page/"
                                                       target="_blank"
                                                       v-html="$t(`Enable job filter on company page.`)">
                                                    </a>
                                                </template>
                                            </v-popover>
                                        </label> <br>
                                        <label class="checkbox">
                                            <input type="checkbox" v-model="form.company.apply_google_index"/>
                                            <span>{{ $t(`Search engine index`) }}</span>
                                            <v-popover offset="10" :disabled="false" style="display: initial">
                                                <button class="ej__tooltip__button ml-1"><i
                                                        class="eicon e-question"></i></button>
                                                <template slot="popover">
                                                    <a class="tooltip-close" v-close-popover><i
                                                            class="eicon e-close"></i></a>
                                                    <a v-close-popover
                                                       href="https://easy.jobs/docs/disable-google-index-for-my-company-page/"
                                                       target="_blank"
                                                       v-html="$t(`How to disable search engine index?`)">
                                                    </a>
                                                </template>
                                            </v-popover>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="section-gap d-flex justify-content-end">
                                <submit-button :click="saveInformation" :loading="isLoading">{{
                                        $t(`Save & Continue`)
                                    }}
                                </submit-button>
                            </div>
                        </div>
                    </form>
                </div>
            </section>
        </div>
    </dashboard-layout>
</template>
<script>


const DashboardLayout = () => import("../../../layouts/DashboardLayout");
const SettingSidebar = () => import("../../../components/company/SettingSidebar");
const ErrorMessage = () => import("../../../components/common/ErrorMessage");

import client from "../../../app/api/Client";


import {UPDATE_COMPANY_DATA} from '../../../constants/action-type';
import {mapActions, mapState} from 'vuex';


import {getCity as loadCity, getCountry as loadCountry, getState as loadState} from "../../../app/api/CommonRequest";
import appConfig from "../../../config/app";
import Multiselect from "vue-multiselect";
import {ckEditorBasicOptions} from "../../../config/options";
import CKEditor from "@ckeditor/ckeditor5-vue2";
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";

export default {
    components: {
        ErrorMessage,
        DashboardLayout,
        SettingSidebar,
        Multiselect,
        ckeditor: CKEditor.component

    },
    data() {
        return {
            editor: DecoupledEditor,
            form: {
                company: {
                    name: null,
                    username: null,
                    mobile_number: null,
                    company_type: {id: null},
                    website: null,
                    company_size: null,
                    description: null,
                    benefits: null,
                    show_explore_company: null,
                    show_job_filter: null,
                    apply_google_index: true
                },
                companyAddress: {
                    postal_code: null,
                    country: null,
                    state: null,
                    city: null,
                },
                brand_color: '#597dfc',
                lang: null
            },
            errors: {
                benefits: ''
            },
            isLoading: false,
            companyTypes: [],
            countries: [],
            states: [],
            cities: [],
            timeZones: [],
            timezone: {
                time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone
            }
        };
    },
    computed: {
        ...mapState(['company']),
        languages() {
            let items = appConfig.lang.sort((a, b) => {
                return (a.name < b.name) ? -1 : (a.name > b.name) ? 1 : 0
            });

            return items.map(l => {
                if (!_.isEmpty(l.extra)) {
                    l.title = l.name + ' ' + l.extra;
                    // l.name = l.name + ' ' + l.extra;
                } else {
                    l.title = l.name;
                }
                return l;
            });
        },
        CDEditorConfig() {
            let placeholderConfig = {'placeholder': this.$t(`Write description here.`)};
            return {...ckEditorBasicOptions, ...placeholderConfig};
        },
        CBEditorConfig() {
            let placeholderConfig = {'placeholder': this.$t(`Write company benefit here.`)};
            return {...ckEditorBasicOptions, ...placeholderConfig};
        }
    },
    methods: {
        ...mapActions([UPDATE_COMPANY_DATA]),
        onCBReady(editor) {
            const toolbarContainer = document.querySelector('#company-benefits');
            toolbarContainer.prepend(editor.ui.view.toolbar.element);
        },
        onCDReady(editor) {
            const toolbarContainer = document.querySelector('#company-description');
            toolbarContainer.prepend(editor.ui.view.toolbar.element);
        },
        async getTimeZones() {
            let {data: {data}} = await client().get('/timezones');
            this.timeZones = data;
        },
        croppie(target, e) {

            this.showCropperModal();

            this.target = target;

            var files = e.target.files || e.dataTransfer.files;
            if (!files.length) return;

            this.tempfile = files[0];

            var reader = new FileReader();

            reader.onload = e => {
                this.$refs.croppieRef.bind({
                    url: e.target.result
                });
            };

            reader.readAsDataURL(this.tempfile);
        },
        saveInformation() {
            this.isLoading = true;
            this.form.company.time_zone = this.timezone.time_zone;
            client().post('/company/setting/basic-info', this.form)
                .then(({data: {data, message}}) => {

                    if (data.hasOwnProperty('name') && data.hasOwnProperty('id')) {

                        this[UPDATE_COMPANY_DATA](data);
                    }

                    this.$toast.success(message);

                    this.errors = [];

                })
                .catch(error => {
                    return this.showErrorText(error)
                })
                .finally(res => {
                    this.isLoading = false;
                });
        },
        getBasicInfo() {
            client()
                .get("/company/setting/basic-info")
                .then(({data}) => {
                    this.form.company = data.data.company;
                    this.form.companyAddress = data.data.companyAddress;
                    this.companyTypes = data.data.companyTypes;
                    this.logoImgSrc = data.data.logo;
                    this.faviconSrc = data.data.favicon;
                    this.hasLogo = data.data.hasLogo;
                    this.hasFavicon = data.data.hasFavicon;
                    this.form.lang = _.find(this.languages, {code: data.data.lang});
                    this.form.brand_color = data.data.brand_color;
                    if (this.form.company.time_zone) {
                        this.timezone.time_zone = this.form.company.time_zone;
                    }
                    // scroll to hash if available
                    this.scrollToHash();
                })
                .catch(error => {
                    return this.showErrorText(error)
                });

        },


        resetCountryAddress() {
            this.form.companyAddress.city = null;
            this.form.companyAddress.state = null;
        },
        resetStateAddress() {
            this.form.companyAddress.city = null;
        },

        getCountry() {
            loadCountry().then((data) => {
                this.countries = data;

            }).catch(error => {
                return this.showErrorText(error)
            });
        },

        getState(option) {

            if (!this.form.companyAddress.country) {
                this.$toast.error(this.$t(`Please select country first`));
                return 0;
            }

            loadState(this.form.companyAddress.country.id).then((data) => {
                this.states = data;
            }).catch(error => {
                return this.showErrorText(error)
            });
        },

        getCity(option) {
            if (!this.form.companyAddress.country || !this.form.companyAddress.state) {
                this.$toast.error(this.$t(`Please select country & state first`));
                return 0;
            }

            loadCity(this.form.companyAddress.country.id, this.form.companyAddress.state.id).then((data) => {
                this.cities = data;
            }).catch(error => {
                return this.showErrorText(error)
            });
        },

        showErrorText(error) {

            if (typeof error == 'string') {
                return this.$toast.error(error);
            } else if (error.response !== undefined && error.response.status === 422) {/* validation error */

                if (typeof error.response.data.message == 'string') {

                    return this.$toast.error(error.response.data.message);/* System error */
                }
                this.errors = _helper.serializeValidationMessage(error.response.data.message);
                return this.$toast.error(this.$t(`Oops! Please check your input`));
            } else {
                // this.$toast.error(this.$t(`Oops! Somethings was wrong`));
            }
        },

        async removeCompanyPhoto(target) {
            try {
                let confirm = await this.$dialog.confirm({
                    title: this.$t(`Confirmation`),
                    body: this.$t(`Are you sure you want to remove ` + target.split('_').join(' ') + '?')
                });

                if (!confirm) return false;

                let {data: {data: company, message}} = await client().post(`/company/setting/company-photo/`, {
                    _method: 'DELETE',
                    target
                });
                if (target === 'company_logo') {
                    this.logoImgSrc = "/assets/images/placeholder/company_150X150.jpeg";
                    this.hasLogo = false;
                    company.logo = '';
                    this[UPDATE_COMPANY_DATA](company);
                } else if (target === 'company_favicon') {
                    this.faviconSrc = '/favicon.png';
                    this.hasFavicon = false;
                }
                this.$toast.success(message);
            } catch (e) {
            }
        }
    },
    mounted() {
        this.getTimeZones();
        this.getBasicInfo();
    },
    updated() {
        if (this.$route.query.focus === "website_url") {
            this.$nextTick(() => {
                let editButtonRef = this.$refs.website_url;
                if (editButtonRef) {
                    editButtonRef.focus();
                }
                let scrollBlock =  document.getElementById('website-block');
                if (scrollBlock) {
                    scrollBlock.scrollIntoView({behavior: "smooth", top: 200});
                }
            });
        }
    }
};
</script>
